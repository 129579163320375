import * as api from "../Services/api.js";
import SeitResource from "../../objs/SeitResource";

export const state = {
  seitResources: [],
};

export const mutations = {
  SET_SEIT_RESOURCES(state, payload) {
    state.seitResources = payload.map((item) => new SeitResource(item));
  },
};

export const getters = {
  seitResource: (state) => (resourceId) => {
    return state.seitResources.find(
      (item) => item.seitResourceId === resourceId
    );
  },
};

export const actions = {
  async getSeitResources({ commit }) {
    await api.get("/knowledgeBase/getSeitResources").then((response) => {
      if (response.success) {
        commit("SET_SEIT_RESOURCES", response.data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
