import axios from "axios";
var omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL + "/api";
var securityApiUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL;

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getClientsForUser(data) {
  return axios
    .get(securityApiUrlPrefix + "/api/identity/getClientsForUser", {
      params: {
        userName: data,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export async function get(subURL) {
  return axios
    .get(omniApiUrlPrefix + subURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function post(path, data) {
  return axios
    .post(omniApiUrlPrefix + path, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
