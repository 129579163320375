import mgr from "../../security/security.js";
import * as api from "../Services/api";

export const state = {
  isAuthenticated: false,
  user: "",
  requiresAuth: false,
};

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setRequiresAuth(state, payload) {
    state.requiresAuth = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
};

export const getters = {};

export const actions = {
  async shallowAuthenticate({ commit, state, dispatch }) {
    await mgr
      .signinSilent()
      .then((user) => {})
      .catch((e) => {});

    let user = await mgr.getUser();
    if (!!user) {
      commit("setUser", user);
      // load whatever user data is specific to the application here
      commit("setIsAuthenticated", true);
    } else {
      commit("setIsAuthenticated", false);
    }
  },
  async authenticate({ commit, state, dispatch }, returnPath) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    let user = await mgr.getUser();
    if (!!user) {
      commit("setUser", user);
      // load whatever user data is specific to the application here
      commit("setIsAuthenticated", true);
    } else {
      var signedIn = (await returnPath)
        ? mgr.signinRedirect({ state: returnPath })
        : mgr.signinRedirect();
    }

    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
  async logout({ commit, state }) {
    commit("setIsAuthenticated", false);
    await mgr.signoutRedirect();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
