import Home from "../components/SEITDocs/home";
import Workshops from "../components/SEITDocs/workshops";
import Callback from "../components/Security/callback";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Workshops",
    path: "/workshops",
    icon: "caret-right",
    title: "Workshops",
    component: Workshops,
    props: true,
    onNavBar: false,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  },
];
