export default class SeitResource {
  seitResourceId?: string | null;
  name?: string | null;
  topic?: string | null;
  fileId?: number | null;
  fileName?: string | null;
  fileExtension?: string | null;
  url?: string | null;
  attachedDocuments?: SeitResource[];
  public constructor(params: SeitResource = {} as SeitResource) {
    let {
      seitResourceId = "",
      name = "",
      topic = "",
      fileId = null,
      fileName = "",
      fileExtension = "",
      url = "",
      attachedDocuments = [],
    } = params;
    this.seitResourceId = seitResourceId;
    this.name = name;
    this.topic = topic;
    this.fileId = fileId;
    this.fileName = fileName;
    this.fileExtension = fileExtension;
    this.url = url;
    this.attachedDocuments =
      attachedDocuments && attachedDocuments.map((i) => new SeitResource(i));
  }

  get isVideo() {
    return this.fileId != null && this.fileExtension == "video/mp4";
  }
}
