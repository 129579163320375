<template>
  <v-container fluid>
    <v-text-field
      v-model="searchQuery"
      label="Search SEIT Documents, Forms, and Workshops"
      prepend-icon="mdi-magnify"
      clearable
    ></v-text-field>
    <v-row dense>
      <template v-for="(group, topic, index) in groupedResources">
        <v-col :key="index">
          <v-card class="pa-2" outlined tile>
            <v-card-title>{{ topic }}</v-card-title>
            <v-list flat dense>
              <v-list-item-group>
                <v-list-item
                  nav
                  v-for="item in group"
                  :key="item.seitResourceId"
                  @click="onClick(item)"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon v-if="item.isVideo">mdi-play-box-outline</v-icon>
                    <v-icon v-else-if="Boolean(item.url)"
                      >mdi-link-box-outline</v-icon
                    >
                    <v-icon v-else>mdi-download-box-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-responsive
          v-if="index === 2"
          :key="`width-${index}`"
          width="100%"
        ></v-responsive>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { downloadFileUrl } from "../../utils/download-utils";
export default {
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    seitResources() {
      return this.$store.state.seitDocsModule.seitResources || [];
    },
    filteredResources() {
      if (Boolean(this.searchQuery)) {
        return this.seitResources.filter((item) => {
          return (
            item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.topic?.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      } else {
        return this.seitResources;
      }
    },
    groupedResources() {
      return this.filteredResources.reduce((group, resource) => {
        if (Boolean(resource.topic)) {
          group[resource.topic] = group[resource.topic] || [];
          group[resource.topic].push(resource);
        }
        return group;
      }, {});
    },
  },
  methods: {
    onClick(item) {
      if (item.isVideo) {
        this.showVideo(item);
      } else if (item.url) {
        window.open(item.url);
      } else {
        this.downloadFile(item);
      }
    },
    downloadFile: async function (item) {
      this.$store.commit("uxModule/setShowLoader", true);
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: item.fileId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (fileUrl) {
        downloadFileUrl(fileUrl, item.fileName);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
    showVideo: async function (item) {
      this.$router.push({
        name: "Workshops",
        query: { resourceId: item.seitResourceId },
      });
    },
  },
};
</script>
