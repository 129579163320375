<template>
  <v-container>
    <v-btn outlined small @click="goBack"
      ><v-icon small>mdi-arrow-left</v-icon>Back</v-btn
    >
    <v-card flat outlined class="ma-auto">
      <v-card-title class="justify-center">{{ workshop.name }}</v-card-title>
      <v-card-text>
        <v-row class="justify-center">
          <video width="500" height="auto" controls :key="url">
            <source :src="url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </v-row>
        <v-row class="justify-center">
          <v-list flat>
            <v-list-item-group>
              <v-list-item
                nav
                v-for="item in workshop.attachedDocuments"
                :key="item.seitResourceId"
                @click="onClick(item)"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon> mdi-download-box-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { downloadFileUrl } from "../../utils/download-utils";
export default {
  data() {
    return {
      url: "",
    };
  },
  async mounted() {
    if (Object.keys(this.workshop).length == 0) {
      this.goBack();
      return;
    }
    this.$store.commit("uxModule/setShowLoader", true);
    this.url = await this.$store.dispatch("filesModule/getFileUrl", {
      fileId: this.workshop.fileId,
    });
    this.$store.commit("uxModule/setShowLoader", false);
  },
  computed: {
    resourceId() {
      return this.$route.query.resourceId || 0;
    },
    workshop() {
      return (
        this.$store.getters["seitDocsModule/seitResource"](this.resourceId) ||
        {}
      );
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Home" });
    },
    onClick(item) {
      this.downloadFile(item);
    },
    downloadFile: async function (item) {
      this.$store.commit("uxModule/setShowLoader", true);
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: item.fileId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (fileUrl) {
        downloadFileUrl(fileUrl, item.fileName);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
