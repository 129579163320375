import axios from "axios";

export const downloadFileUrl = async (fileUrl, fileName) => {
  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    downloadFile(response.data, fileName);
  });
};

export const downloadFile = async (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
